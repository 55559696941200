import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TPartners = t.type({
  name: t.string,
  code: t.string,
  countReceiver: t.number,
})

export const TRegions = t.type({
  addressDetailsId: t.string,
  addressType: t.string,
  descriptionUA: t.union([t.string, t.undefined]),
  countOperators: t.number,
})

export const TNotificationsViewed = t.type({
  notificationId: t.string,
  createdAt: DateFromISOString,
  viewedAt: t.union([DateFromISOString, t.undefined]),
  title: t.string,
  text: t.string,
  viewed: t.boolean,
})

export const TUserView = t.type({
  branchInfo: t.string,
  viewedAt: t.union([DateFromISOString, t.undefined, t.string]),
  operatorsPhone: t.string,
  viewed: t.boolean,
})

export const TArchiveNotifications = t.type({
  notificationId: t.string,
  createdAt: DateFromISOString,
  title: t.string,
  text: t.string,
  fileIds: t.union([t.array(t.string), t.undefined]),
  countReceiver: t.number,
  receiverType: t.string,
  creatorName: t.string,
})

export const TBranch = t.type({
  branchId: t.string,
  branchNumber: t.number,
  city: t.string,
  fullAddress: t.string,
  countReceiver: t.number,
})

export const TBranches = t.union([t.array(TBranch), t.undefined])

export type Partners = t.TypeOf<typeof TPartners>

export type NotificationsViewed = t.TypeOf<typeof TNotificationsViewed>

export type ArchiveNotifications = t.TypeOf<typeof TArchiveNotifications>
