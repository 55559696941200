import { Divider } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { Box } from '@material-ui/system'
import { TCashToPay } from 'app/codecs/cash-to-pay'
import { Parcel } from 'app/codecs/parcel'
import { ControlledTextField } from 'components/form/controlled-text-field'
import { FormField } from 'components/form/form-field'
import { DualItems } from 'components/ui/dual-items'
import { FormDialog } from 'components/ui/form-dialog/form-dialog'
import { useNotificationContext } from 'hooks/use-notification'
import { formatDate } from 'lib/format-date/format-date'
import { useQuery } from 'lib/rest-query'
import { concatQueryParams } from 'lib/rest-query/common'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

type FieldValues = {
  totalAmount: string
}

type Props = {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  parcels: Array<Parcel>
  branchId: string
  returnRequest?: string
}

export const CashPaymentDialog = ({
  isOpen,
  onClose,
  parcels,
  onSuccess,
  branchId,
  returnRequest,
}: Props) => {
  const { t } = useTranslation()
  const form = useForm<FieldValues>({
    defaultValues: {
      totalAmount: '',
    },
  })
  const { control, watch } = form
  const { showSuccessNotification, showErrorNotification } =
    useNotificationContext()

  const parcelIds = parcels.map(parcel => parcel.parcelId)
  const $pay = useMutation('POST', '/api/pudo/parcels/cash/confirm')

  const location = useLocation()

  const $parcelsToPay = useQuery(
    'PUT',
    concatQueryParams(
      '/api/pudo/parcels/cash/arrears',
      new URLSearchParams({
        branchId,
      }),
    ),
    TCashToPay,
    {
      rawBody: {
        parcelIds,
        returnRequest,
      },
      options: {
        enabled: isOpen === true,
      },
    },
  )

  type ParcelToPay = {
    parcelId: string
    parcelNumber: string
    amount?: number
    cod?: number
  }

  const fetchedParcelsToPay = $parcelsToPay.data
  const parcelsToPay = fetchedParcelsToPay?.cashPaymentDept.reduce(
    (arr: Array<ParcelToPay>, curr) => {
      const existingParcel = arr.find(obj => obj.parcelId === curr.parcelId)

      if (existingParcel) {
        existingParcel.amount =
          curr.paymentType === 'DELIVERY' ? curr.amount : existingParcel.amount
        existingParcel.cod =
          curr.paymentType === 'CASH_ON_DELIVERY'
            ? curr.amount
            : existingParcel.cod
      } else {
        arr.push({
          parcelId: curr.parcelId,
          parcelNumber: curr.parcelNumber,
          amount: curr.paymentType === 'DELIVERY' ? curr.amount : undefined,
          cod:
            curr.paymentType === 'CASH_ON_DELIVERY' ? curr.amount : undefined,
        })
      }

      return arr
    },
    [],
  )

  const totalAmount = watch('totalAmount')

  const rest =
    fetchedParcelsToPay?.totalAmount &&
    Number(totalAmount) - fetchedParcelsToPay?.totalAmount > 0
      ? (Number(totalAmount) - fetchedParcelsToPay?.totalAmount).toFixed(2)
      : null

  const onSubmit = ({ totalAmount }: FieldValues) => {
    if (
      fetchedParcelsToPay?.totalAmount &&
      Number(totalAmount) - fetchedParcelsToPay?.totalAmount < 0
    ) {
      return showErrorNotification(t('notifications.cash_payment_error'))
    }
    $pay.mutate(
      {
        body: {
          parcelIds,
          totalAmount,
        },
        search: new URLSearchParams({
          branchId,
          isParcelReception: (
            location.pathname === '/client-parcel-reception'
          ).toString(),
        }),
      },
      {
        onSuccess: () => {
          onSuccess()
          showSuccessNotification(t('notifications.cash_payment'))
          onClose()
        },
      },
    )
  }

  return (
    <FormDialog
      title={t('dialog_titles.cash_payment')}
      open={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      closeDialogTitle={'dialog_confirm.cash_payment'}
      form={form}
      actions={
        <LoadingButton loading={$pay.isLoading} fullWidth type="submit">
          {t('buttons.pay')}
        </LoadingButton>
      }
    >
      {parcelsToPay?.map(parcel => (
        <Fragment key={parcel.parcelId}>
          <DualItems
            view="row"
            items={[
              {
                label: 'parcel.number',
                value: parcel.parcelNumber,
                labelColor: 'textSecondary',
                valueVariant: 'body2',
              },
              {
                label: 'form_fields.delivery_value',
                value: parcel.amount,
                labelColor: 'textSecondary',
                valueVariant: 'body2',
              },
              {
                label: 'form_fields.cod',
                value: parcel.cod ?? 0,
                labelColor: 'textSecondary',
                valueVariant: 'body2',
              },
            ]}
          />
          <Divider sx={{ my: 2 }} />
        </Fragment>
      ))}
      <Box mt={3}>
        <DualItems
          view="row"
          items={[
            {
              label: 'parcel.cash_payment',
              value: fetchedParcelsToPay?.totalAmount,
              labelColor: 'textPrimary',
              valueVariant: 'body1',
            },
          ]}
        />
      </Box>
      <Box mt={2} mb={3}>
        <DualItems
          view="row"
          items={[
            {
              label: 'parcel.payment_date',
              value: formatDate(new Date()),
              labelColor: 'textSecondary',
              valueVariant: 'body2',
            },
          ]}
        />
      </Box>
      <Divider sx={{ my: 2 }} />
      <FormField>
        <ControlledTextField
          required
          control={control}
          name="totalAmount"
          label={t('form_fields.cash_payed')}
        />
      </FormField>
      {rest && (
        <Box mt={2}>
          <DualItems
            view="row"
            items={[
              {
                label: 'form_fields.rest',
                value: rest,
                labelColor: 'textSecondary',
                valueVariant: 'body1',
              },
            ]}
          />
        </Box>
      )}
    </FormDialog>
  )
}
