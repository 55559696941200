import { Box } from '@material-ui/core'
import { Service, Size } from 'app/codecs/parcel'
import { CurrentStepFields } from 'components/form/form-step/current-step-fields'
import { ParcelDetailsStep } from 'components/forms/create-parcel/steps/parcel-details-step'
import { ParcelInfoStep } from 'components/forms/create-parcel/steps/parcel-info-step'
import { ReceiverInfoStep } from 'components/forms/create-parcel/steps/receiver-info-step'
import { SenderInfoStep } from 'components/forms/create-parcel/steps/sender-info-step'
import { Stepper } from 'components/ui/stepper'
import {
  Control,
  UseFormClearErrors,
  UseFormReturn,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

export type Places = {
  size?: Size
  insurance: string
}

export type ParcelFieldValues = {
  places: Array<Places>
  receiverPay: string | boolean
  sender: {
    firstname: string
    middleName: string
    lastname: string
    number: string
  }
  receiver: {
    firstname: string
    middleName: string
    lastname: string
    service?: Service
    number: string
    branchCity: {
      label: string
      value: {
        cityNameUA: string
        cityNameRU: string
        cityType: string
        cityId: string
      }
    } | null
    deliveryPlaceId: {
      label: string
      value: string
    } | null
    addressRequest: {
      street: {
        label: string
        value: {
          streetNameUA: string
          streetNameRU: string
          streetType: string
          streetId: string
        }
      } | null
      building: string
      corps: string
      flat: string
      notes: string
      city: {
        label: string
        value: {
          cityNameUA: string
          cityNameRU: string
          cityType: string
          cityId: string
        }
      } | null
    }
  }
  branchForCOD: {
    branchId: {
      label: string
      value: string
    } | null
    branchCity: {
      label: string
      value: {
        cityNameUA: string
        cityNameRU: string
        cityType: string
        cityId: string
      }
    } | null
    receiver: {
      firstname: string
      middleName: string
      lastname: string
    }
    payer: {
      firstname: string
      middleName: string
      lastname: string
    }
  }
  afterPay: string | null
  deliveryCash: {
    cardNumber: string
    cod: string
  }
  notes: string
  branchAdditionalOption: boolean
  courierAdditionalOptions: Array<string>
  isCreateError?: boolean
}

const formStepsMapping: {
  [key: number]: {
    component: (props: {
      control: Control<ParcelFieldValues>
      watch: UseFormWatch<ParcelFieldValues>
      clearErrors: UseFormClearErrors<ParcelFieldValues>
      setValue: UseFormSetValue<ParcelFieldValues>
    }) => JSX.Element | null
    title: string
  }
} = {
  0: {
    component: ParcelInfoStep,
    title: 'step_titles.parcel_info',
  },
  1: {
    component: SenderInfoStep,
    title: 'step_titles.sender_info',
  },
  2: {
    component: ReceiverInfoStep,
    title: 'step_titles.receiver_info',
  },
  3: {
    component: ParcelDetailsStep,
    title: 'step_titles.total',
  },
}

export const ParcelForm = (
  form: UseFormReturn<ParcelFieldValues>,
  activeStep: number,
  steps?: number,
) => {
  const { control, watch, clearErrors, setValue } = form

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Stepper steps={steps || 0} activeStep={activeStep} />
        {activeStep !== undefined && (
          <CurrentStepFields
            step={activeStep}
            control={control}
            watch={watch}
            setValue={setValue}
            formStepsMapping={formStepsMapping}
            clearErrors={clearErrors}
          />
        )}
      </Box>
    </>
  )
}
