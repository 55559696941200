import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconTerminals = (
  props: SvgIconProps & { strokecolor: string },
) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path
      d="M11.5946 16.8368C11.7014 16.941 11.8502 17 12.0056 17C12.1612 17 12.3097 16.941 12.4168 16.8368L16.9664 12.4542C19.6779 9.85498 19.6779 5.60907 16.9664 2.98371C15.6476 1.70515 13.8838 1 12 1C10.1162 1 8.35216 1.70515 7.03364 2.97837C4.32212 5.57742 4.32212 9.82353 7.03364 12.4489L11.5946 16.8368ZM7.86694 3.70962C8.96872 2.64172 10.4415 2.05228 12.0056 2.05228C13.5698 2.05228 15.0427 2.64172 16.1442 3.70962C18.4392 5.9301 18.4392 9.52881 16.1442 11.7281L12.0056 15.711L7.86694 11.7281C5.57218 9.50785 5.57218 5.90893 7.86694 3.70962Z"
      fill="#2C363F"
      stroke={props.strokecolor}
    />
    <path
      d="M11.9999 10C13.6568 10 15 8.65684 15 7.0001C15 5.34316 13.6568 4 11.9999 4C10.3432 4 9 5.34316 9 7.0001C9.00184 8.65603 10.344 9.99816 11.9999 10ZM11.9999 5.0506C13.0795 5.0506 13.9547 5.92582 13.9547 7.00521C13.9547 8.08479 13.0795 8.96002 11.9999 8.96002C10.9205 8.96002 10.0453 8.08479 10.0453 7.00521C10.0473 5.92664 10.9213 5.05264 11.9999 5.0506Z"
      fill="#2C363F"
      stroke={props.strokecolor}
    />
    <path
      d="M16.1119 19.4948C15.0095 19.1762 13.5498 19 11.9999 19C10.4502 19 8.99049 19.1762 7.88811 19.4948C6.63576 19.8575 6 20.366 6 21C6 21.6339 6.63576 22.1423 7.88811 22.505C8.99049 22.8236 10.4502 23 11.9999 23C13.5498 23 15.0095 22.8236 16.1119 22.505C17.3642 22.1423 18 21.6339 18 21C17.9972 20.366 17.3642 19.8575 16.1119 19.4948ZM15.9863 21.8475C14.925 22.1559 13.5088 22.3253 11.9999 22.3253C10.4912 22.3253 9.07501 22.1559 8.01366 21.8475C6.81578 21.4983 6.54847 21.1084 6.54847 21C6.54847 20.8914 6.81855 20.5016 8.01366 20.1559C9.07501 19.8475 10.4912 19.678 11.9999 19.678C13.5088 19.678 14.925 19.8475 15.9863 20.1559C17.1841 20.505 17.4515 20.8914 17.4515 21C17.4515 21.1084 17.1841 21.4983 15.9863 21.8475Z"
      fill="#2C363F"
      stroke={props.strokecolor}
    />
  </SvgIcon>
)
