import { Parcel } from 'app/codecs/parcel'
import { Places } from 'components/forms/create-parcel/parcel-form'
import { isSome } from 'fp-ts/lib/Option'

export type MeestDocumentType =
  | 'PASSPORT'
  | 'ID'
  | 'DRIVER_LICENSE'
  | 'TRAVEL_PASSPORT'
  | 'OTHER'

export type MeestVerification = {
  identificationType?: 'CALL' | 'DOCUMENT' | 'QR' | 'BAR'
  smsCode: string
  document?: {
    type: MeestDocumentType
    name: string
    id: string
  }
}

const WEIGHT_DOCUMENTS = 0.5
const WEIGHT_XS = 1
const WEIGHT_S = 2
const WEIGHT_M = 10
const WEIGHT_L = 30

const NOMENCLATURE_OPTION_ID = '6d4eefed-ec08-11df-b61a-00215aee3ebe'

export const getAuthenticationType = (values: MeestVerification) => {
  if (values.identificationType === 'CALL') {
    return 'CALL'
  }

  if (values.document) {
    return values.document.type
  }
}

export const getAuthenticationData = (values: MeestVerification) => {
  if (values.identificationType === 'CALL') {
    return values.smsCode
  }

  if (values.document) {
    return values.document.id
  }
}

export const getDocumentType = (values: MeestVerification) => {
  if (values.document) {
    return values.document.type
  }
}

export const getDocumentName = (values: MeestVerification) => {
  if (values.document) {
    return values.document.name
  }
}

export const getDocumentData = (values: MeestVerification) => {
  if (values.document) {
    return values.document.id
  }
}

export const getShortTitleFromText = (input: string, limit: number) => {
  const regex = /<[^>]*>/g
  let result = input.replace(regex, '')

  if (result.length > limit) {
    result = result.slice(0, limit)

    return result + '...'
  } else return result
}

export const getTextWithoutTags = (input: string) => {
  const regex = /<[^>]*>/g
  return input.replace(regex, '')
}

export const defaultContentManagerCity = {
  label: 'м.Львів, Львів район, ЛЬВІВСЬКА обл.',
  value: {
    cityId: '62c3d54a-749b-11df-b112-00215aee3ebe',
    cityNameRU: 'Львов',
    cityNameUA: 'Львів',
    cityType: 'місто',
  },
}

export const defaultContentManagerBranch = {
  label: '№220, ЛЬВІВСЬКА область, Львів, вул. Зелена 2 7/9/8/6',
  value: 'e896ac12-35e5-11ee-80bd-000c2961d091',
}

export const countPlacesSum = (places: Array<Places>) => {
  let actualWeight = 0

  for (const place of places) {
    switch (place.size) {
      case 'DOCUMENTS':
        actualWeight += WEIGHT_DOCUMENTS
        break
      case 'XS':
        actualWeight += WEIGHT_XS
        break
      case 'S':
        actualWeight += WEIGHT_S
        break
      case 'M':
        actualWeight += WEIGHT_M
        break
      case 'L':
        actualWeight += WEIGHT_L
        break
      default:
        break
    }
  }

  return actualWeight
}

export const hasNomenclatureOption = (parcel: Parcel) => {
  if (isSome(parcel.notation) && parcel.notation.value === 'Продаж тари')
    return true
  else if (parcel.additionalOptions)
    parcel.additionalOptions.some(
      option => option.optionId === NOMENCLATURE_OPTION_ID,
    )
  else return false
}
