import {
  Box,
  Button,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { useAuthContext } from 'app/auth'
import { isSuperAdminRole } from 'app/codecs/user'
import { Dialog } from 'components/ui/dialog'
import { useTranslation } from 'react-i18next'

type Props = {
  isOpen: boolean
  onClose: () => void
  isSendPaymentLinkLoading: boolean
  isCashPaymentAvailable?: boolean
  isPosAvailable?: boolean
  onSendPaymentLink: () => void
  onCashPayment: () => void
  onECheckPayment: () => void
}

export const PaymentDialog = ({
  isOpen,
  onClose,
  isSendPaymentLinkLoading,
  isCashPaymentAvailable,
  isPosAvailable,
  onSendPaymentLink,
  onCashPayment,
  onECheckPayment,
}: Props) => {
  const { t } = useTranslation()
  const { auth } = useAuthContext()

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      title={t('dialog_titles.select_payment_method')}
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={mdDown ? 'column' : 'row'}
          gap={mdDown ? 1 : 0}
        >
          {isCashPaymentAvailable && (
            <Button onClick={onCashPayment} fullWidth={mdDown}>
              {t('buttons.pay_cash')}
            </Button>
          )}
          <LoadingButton
            loading={isSendPaymentLinkLoading}
            onClick={onSendPaymentLink}
            fullWidth={mdDown || !isCashPaymentAvailable}
          >
            {t('buttons.send_payment_link')}
          </LoadingButton>
          {(isPosAvailable || isSuperAdminRole(auth.role)) && (
            <Button onClick={onECheckPayment} fullWidth={mdDown}>
              {t('buttons.pay_echeck')}
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
