import { Box, Stack, Typography } from '@material-ui/core'
import { useNotificationContext } from 'hooks/use-notification'
import { isEmpty } from 'lib/nullable'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export type DualItemType = {
  label: string
  value?: ReactNode
  highlighted?: boolean
  values?: Array<string | number>
  align?: 'start' | 'center' | 'end'
  labelColor?: 'textPrimary' | 'textSecondary'
  valueVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'body1'
    | 'body2'
    | 'button'
    | 'inherit'
    | 'caption1'
    | 'caption2'
  isCopied?: boolean
}

type Props = {
  items: Array<DualItemType>
  view: 'column' | 'row' | 'row-centered'
}

export const DualItems = ({ items, view }: Props) => {
  const { t } = useTranslation()
  const { showSuccessNotification } = useNotificationContext()

  const isVertical = view === 'column'
  const isHorizontalCentered = view === 'row-centered'

  const renderLabel = (item: DualItemType) => (
    <Typography
      variant="body2"
      color={item.labelColor}
      textAlign={isHorizontalCentered ? 'end' : 'start'}
      fontSize={item.highlighted ? '1rem' : 'none'}
      fontWeight={item.highlighted ? 500 : 'none'}
      flex={1}
    >
      {t(item.label)}
    </Typography>
  )

  const renderValues = (item: DualItemType) => {
    const values = item.values ?? [item.value]

    return values.map(value => {
      const isTypographyValue =
        typeof value === 'string' || typeof value === 'number'

      return isTypographyValue ? (
        <Box
          flex={1}
          onClick={() => {
            if (item.isCopied) {
              navigator.clipboard.writeText(value.toString())
              showSuccessNotification(t('notifications.number_copied'))
            }
          }}
        >
          <Typography
            sx={{
              wordBreak: 'break-word',
              '&:hover': {
                cursor: item.isCopied ? 'pointer' : 'auto',
              },
            }}
            textAlign={item.align ?? (isHorizontalCentered ? 'start' : 'end')}
            variant={item.valueVariant}
            color={item.highlighted ? 'secondary.light' : 'none'}
            fontSize={item.highlighted ? '1.3125rem' : 'none'}
            fontWeight={item.highlighted ? '700' : 'none'}
            width="100%"
          >
            {value}
          </Typography>
        </Box>
      ) : (
        <Box flex={1}>{value}</Box>
      )
    })
  }

  return (
    <Stack
      spacing={2.5}
      direction={isVertical ? 'row' : 'column'}
      justifyContent="space-between"
    >
      {items.map(
        item =>
          (!isEmpty(item.value) || item.values !== undefined) && (
            <Stack
              key={item.label}
              spacing={3}
              direction={isVertical ? 'column' : 'row'}
              alignItems="center"
              bgcolor={item.highlighted ? 'info.light' : 'none'}
              py={item.highlighted ? 0.5 : 'none'}
            >
              {renderLabel(item)}
              {renderValues(item)}
            </Stack>
          ),
      )}
    </Stack>
  )
}
