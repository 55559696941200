import { Box, Button, Stack, useTheme } from '@material-ui/core'
import { useAuthContext } from 'app/auth'
import { Parcel } from 'app/codecs/parcel'
import { TProfile } from 'app/codecs/user'
import { isAdminRole, isSuperAdminRole } from 'app/codecs/user'
import { IconCross } from 'assets/icons'
import { DualItems, DualItemType } from 'components/ui/dual-items'
import { Array as ioArray, string, type } from 'io-ts'
import { getOrDefault } from 'lib/nullable'
import { useQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useEffect, useState } from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormMode,
  ParcelIdentificationFieldValues,
  Sticker,
} from './parcel-actions-form-dialog'
import { StickerFields } from './sticker-fields'
import { isParcelIssuanceMode, isParcelReceptionMode } from './utils'

type Props = {
  mode: FormMode
  parcel: Parcel
  control: Control<ParcelIdentificationFieldValues>
  stickerFields: Array<Sticker>
  isSeveralParcels: boolean
  setValue: UseFormSetValue<ParcelIdentificationFieldValues>
  renderParcelDetails: (parcel: Parcel) => Array<DualItemType>
  onRefusalParcel: (parcelId: string) => void
  readyToReception?: (val: boolean) => void
  children?: React.ReactNode
}

export const ParcelDetails = ({
  mode,
  control,
  stickerFields,
  parcel,
  isSeveralParcels,
  setValue,
  onRefusalParcel,
  renderParcelDetails,
  readyToReception,
  children,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { auth } = useAuthContext()
  const [printStickerClick, setPrintStickerClick] = useState(false)
  const [hasPrinter, setHasPrinter] = useState(false)

  // eslint-disable-next-line
  const $getStickerFile = useMutation('POST', '/api/pudo/print/sticker100/')

  const $getPrinterOnBranch = useMutation(
    'GET',
    `/api/pudo/branches/:branchId/printer`,
    type({
      printer: ioArray,
    }),
  )

  /* eslint-disable */
  useEffect(() => {
    $getPrinterOnBranch.mutate(
      { params: { branchId: auth.branch ? auth.branch.branchId : '' } },
      {
        onSuccess: data => {
          setHasPrinter(data?.printer ? !!data.printer[0] : false)
        },
      },
    )
  }, [])
  /* eslint-enable */

  const handleStickerPrint = async () => {
    setPrintStickerClick(true)
    const token = auth?.type === 'authenticated' ? auth.accessToken : null

    const headers = new Headers({
      'Screen-Width': `${screen.width}`,
      'Screen-Height': `${screen.height}`,
    })

    headers.set('Content-Type', 'application/json')

    headers.set('Authorization', `Bearer ${token}`)

    try {
      const response = await window.fetch('/api/pudo/print/sticker100/', {
        method: 'POST',
        body: JSON.stringify({ parcelIds: [parcel.parcelId] }),
        headers,
      })

      if (response.ok) {
        try {
          if (response) {
            let reader = response.body?.getReader()
            // eslint-disable-next-line
            let buffer = new Array()
            reader
              ?.read()
              .then(function readFile({ value, done }): void | Promise<void> {
                if (done) {
                  if (buffer.length > 0) {
                    let blob = new Blob(buffer, {
                      type: 'application/pdf',
                    })
                    let blobURL = URL.createObjectURL(blob)
                    let iframe = document.createElement('iframe')
                    document.body.append(iframe)

                    iframe.style.display = 'none'
                    iframe.src = blobURL
                    iframe.addEventListener('load', function () {
                      setTimeout(function () {
                        iframe.focus()
                        iframe.contentWindow?.print()
                      }, 1)
                    })
                  }

                  return
                }

                buffer.push(value)

                return reader?.read().then(readFile)
              })
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const handleApproveSticker = () => {
    if (readyToReception) {
      readyToReception(false)
    }
  }

  const $user = useQuery('GET', '/api/pudo/users/me', TProfile)
  const user = $user.data

  const valueOfParcel =
    getOrDefault(parcel.debtCost, 0) + getOrDefault(parcel.debtCOD, 0)

  const sum = `${(
    getOrDefault(parcel.debtCost, 0) + getOrDefault(parcel.debtCOD, 0)
  ).toFixed(2)} грн`

  const $getPrice = useQuery(
    'GET',
    `/api/pudo/parcels/calculate/${auth.branch ? auth.branch.branchId : ''}/${
      parcel.parcelId
    }`,
    type({ costServices: string }),
  )

  const sumRecalc = $getPrice.data
    ? Number.parseFloat($getPrice.data.costServices)
    : 0

  return (
    <>
      <Box
        bgcolor={parcel.paid === true ? 'success.light' : 'error.main'}
        display="flex"
        justifyContent="center"
        py={1.25}
        mb={2}
        borderRadius={1.2}
      >
        {parcel.paid ? (
          t(
            parcel.receiverPay && mode === 'parcel-reception'
              ? 'identification.status_pay_receiver'
              : 'identification.status_paid',
          )
        ) : (
          <Stack
            direction="row"
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            {t('identification.status_unpaid')}
            <Box ml={1}>
              {parcel.status !== 'FOR_RETURNING' &&
              sumRecalc &&
              sumRecalc > valueOfParcel
                ? sumRecalc.toFixed(2) + ' грн.'
                : sum}
            </Box>
          </Stack>
        )}
      </Box>
      {parcel.status !== 'FOR_RETURNING' &&
      !parcel.paid &&
      sumRecalc &&
      sumRecalc > valueOfParcel ? (
        <Box
          style={{
            border: '1px solid #fe6975',
            borderRadius: '10px',
            textAlign: 'center',
            fontSize: '1rem',
            lineHeight: '108%',
            fontFamily: 'Roboto, sans-serif',
          }}
          display="flex"
          justifyContent="center"
          color="#fe6975"
          mb={2}
          py={0.5}
          px={2}
        >
          {t('identification.sum_changed')}
        </Box>
      ) : (
        ''
      )}
      <DualItems view="row-centered" items={renderParcelDetails(parcel)} />
      {isParcelReceptionMode(mode) &&
        !(
          user?.printer &&
          !(isAdminRole(auth.role) || isSuperAdminRole(auth.role))
        ) &&
        !(
          (isAdminRole(auth.role) || isSuperAdminRole(auth.role)) &&
          hasPrinter
        ) &&
        stickerFields.map((field, index) => (
          <StickerFields
            key={field.id}
            id={field.id}
            parcelId={parcel.parcelId}
            setValue={setValue}
            control={control}
            index={index}
          />
        ))}
      {isParcelReceptionMode(mode) &&
        ((user?.printer &&
          !(isAdminRole(auth.role) || isSuperAdminRole(auth.role))) ||
          ((isAdminRole(auth.role) || isSuperAdminRole(auth.role)) &&
            hasPrinter)) && (
          <>
            <Button
              fullWidth
              size="small"
              onClick={handleStickerPrint}
              style={{ marginTop: '2rem' }}
            >
              {t('buttons.print_sticker')}
            </Button>
            <div
              style={{
                marginTop: '2rem',
                marginBottom: '0.5rem',
                color: '#7C8084',
                fontSize: '14px',
                textAlign: 'center',
              }}
            >
              {t('ui.is_sticker_printed')}
            </div>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              width={1}
              spacing={2}
            >
              <Button
                fullWidth
                variant="text"
                color="primary"
                disabled={!printStickerClick}
                onClick={handleApproveSticker}
                style={{ border: '1px solid #0061AF' }}
              >
                {t('dialog_confirm.yes')}
              </Button>
            </Stack>
          </>
        )}
      {isParcelIssuanceMode(mode) && (
        <>
          {children}
          {isSeveralParcels && (
            <Button
              variant="text"
              fullWidth
              size="small"
              startIcon={
                <IconCross strokecolor={theme.palette.secondary.main} />
              }
              onClick={() => onRefusalParcel(parcel.parcelId)}
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.secondary.main,
                bgcolor: theme.palette.error.light,
                fontWeight: 400,
                mt: 1,
                ml: '0px !important',
                '&:hover': {
                  bgcolor: theme.palette.error.main,
                },
              }}
            >
              {t('buttons.rejection')}
            </Button>
          )}
        </>
      )}
    </>
  )
}
