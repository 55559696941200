import { Button, Stack, useTheme } from '@material-ui/core'
import { FormControl } from 'components/ui/form-control'
import { useUuid } from 'hooks/use-uuid'
import { useTranslation } from 'react-i18next'

export type Card = {
  title: string
  value: string
  description: string
  icon: React.ReactNode
}

type Props = {
  label: string
  required?: boolean
  error?: boolean
  onChange: (value: string) => void
  onBlur: () => void
  value: string
  name: string
  helperText?: string
  onFocus?: () => void
}

export const Choice = (props: Props) => {
  const theme = useTheme()
  const id = useUuid()
  const { t } = useTranslation()
  const buttonStyle = (value: boolean) => ({
    backgroundColor: value ? theme.palette.info.main : 'none',
    borderColor: value
      ? theme.palette.primary.main
      : props.error
      ? 'red'
      : 'none',
    color: props.error ? 'red' : 'none',
    '&:hover': {
      color: '#0061AF',
    },
  })

  return (
    <FormControl
      htmlFor={id}
      label={props.label}
      error={props.error}
      required={props.required}
      helperText={props.helperText}
    >
      <Stack
        direction="row"
        onBlur={props.onBlur}
        justifyContent="space-between"
        spacing={1.5}
        data-name={props.name}
      >
        <Button
          variant="outlined"
          sx={buttonStyle(props.value === 'true')}
          onClick={() => props.onChange('true')}
          onFocus={props.onFocus}
          fullWidth
        >
          {t('dialog_confirm.yes')}
        </Button>
        <Button
          variant="outlined"
          sx={buttonStyle(props.value === 'false')}
          onClick={() => props.onChange('false')}
          onFocus={props.onFocus}
          fullWidth
        >
          {t('dialog_confirm.no')}
        </Button>
      </Stack>
    </FormControl>
  )
}
