import { TBranch, TDescription } from 'app/codecs/branch'
import * as t from 'io-ts'
import { DateFromISOString, optionFromNullable } from 'io-ts-types'

export const TService = t.union([
  t.literal('BRANCH'),
  t.literal('COURIER'),
  t.literal('PARCEL_MACHINE'),
])

export const TSize = t.union([
  t.literal('DOCUMENTS'),
  t.literal('XS'),
  t.literal('S'),
  t.literal('M'),
  t.literal('L'),
])

export const TParcelStatus = t.union([
  t.literal('FOR_ISSUING'),
  t.literal('FOR_SENDING'),
  t.literal('SENDING_BACK'),
  t.literal('TRANSFERRED'),
  t.literal('FOR_RECEIVING'),
  t.literal('FOR_RETURNING'),
  t.literal('FOR_CLARIFICATION'),
  t.literal('UNKNOWN'),
  t.literal('ISSUED'),
  t.literal('DELETED'),
  t.literal('RETURNED_TO_SENDER'),
  t.literal('TRANSIT'),
])

export const TDeliveryValue = t.type({
  costServices: t.string,
})

export type Size = t.TypeOf<typeof TSize>

export type Service = t.TypeOf<typeof TService>

const TParcelAddress = t.type({
  addressDetailsId: t.string,
  addressType: TDescription,
  descriptionUA: t.string,
  descriptionRU: t.string,
  descriptionEN: optionFromNullable(t.string),
})

export const TParcelCustomer = t.type({
  address: optionFromNullable(t.string),
  branch: optionFromNullable(TBranch),
  city: optionFromNullable(TParcelAddress),
  country: optionFromNullable(t.string),
  locationDescription: optionFromNullable(t.string),
})

export const TAdditionalOption = t.type({
  optionId: t.string,
  optionName: t.string,
})

const TMinisticker = t.type({
  place: t.string,
  miniSticker: t.string,
})
export const TParcelCreation = t.type({
  parcelId: t.string,
  parcelNumber: t.string,
})

export const TAlternateReceiver = t.type({
  name: t.string,
  phone: t.string,
})

export const TParcel = t.intersection([
  t.type({
    status: TParcelStatus,
    insurance: optionFromNullable(t.number),
    barCode: optionFromNullable(t.string),
    receiptedDate: optionFromNullable(DateFromISOString),
    createdAt: DateFromISOString,
    debtCOD: optionFromNullable(t.number),
    debtCost: optionFromNullable(t.number),
    notation: optionFromNullable(t.string),
    paid: t.boolean,
    parcelId: t.string,
    parcelNumber: t.string,
    quantity: t.number,
    receiverPay: t.boolean,
    receiverPhone: t.string,
    reason: optionFromNullable(t.string),
    weight: t.number,
    miniStickerResponses: optionFromNullable(t.array(TMinisticker)),
    cod: optionFromNullable(t.number),
    international: t.boolean,
  }),
  t.partial({
    stop: t.boolean,
    receiver: TParcelCustomer,
    sender: TParcelCustomer,
    senderName: t.string,
    receiverName: t.string,
    senderPhone: t.string,
    senderVerified: t.boolean,
    additionalOptions: t.array(TAdditionalOption),
    alternateReceiver: TAlternateReceiver,
  }),
])

export const TParcelCashExpired = t.type({
  parcelId: t.string,
  parcelNumber: t.string,
})

export type Parcel = t.TypeOf<typeof TParcel>

type Handlers<TBranch, TCourier, TParcelMachine> = {
  onBranch: () => TBranch
  onCourier: () => TCourier
  onParcelMachine: () => TParcelMachine
}

export const foldService =
  <TBranch, TCourier, TParcelMachine>(
    handlers: Handlers<TBranch, TCourier, TParcelMachine>,
  ) =>
  (service: Service) => {
    if (service === 'BRANCH') {
      return handlers.onBranch()
    }

    if (service === 'COURIER') {
      return handlers.onCourier()
    }

    return handlers.onParcelMachine()
  }

export const TCheckName = t.type({
  number: t.string,
  fullName: t.string,
  firstName: t.string,
  surname: t.string,
  patronymic: t.string,
})

export type CheckName = t.TypeOf<typeof TCheckName>
